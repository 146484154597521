import React from 'react';

import { bbsEndpoint } from '../utils/Constants';

const LoginButton = ({ children }) => {
  const handleClick = () => {
    window.location.href = bbsEndpoint.LOGIN(window.location.href);
  };

  return React.cloneElement(children, { onClick: handleClick });
};

export default LoginButton;
