/* eslint-disable no-script-url */
import React from 'react';
import Link from 'next/link';
import { Avatar, Button, Dropdown, Menu, Collapse, Badge } from 'antd';
import { MenuOutlined, CloseOutlined, BellOutlined } from '@ant-design/icons';

import { navBarItems } from '~/utils/Layout';
import { User } from '~/services/agent';
import LoginButton from '~/components/LoginButton';
import { bbsEndpoint, isServer } from '../utils/Constants';
import Container from '~/components/common/Container';
import NavSearch from '~/components/search/NavSearch';
import TypeaheadSelect from '~/components/search/TypeaheadSelect';

const dropDownMenu = (
  <Menu style={{ marginTop: 10 }}>
    <Menu.Item>
      <Link href="/my">
        <a>我的申请</a>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link href="/account/plan">
        <a>我的套餐</a>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link href="/account/privacy">
        <a>隐私设置</a>
      </Link>
    </Menu.Item>
    {!isServer && (
      <Menu.Item>
        <a href={bbsEndpoint.LOGOUT(window.location.href)}>登出</a>
      </Menu.Item>
    )}
  </Menu>
);
export default function Header({ className }) {
  const { user, isLogin, isVip } = User.getMyProfile();
  const [visible, setVisible] = React.useState(false);
  // const vipExpiresInDays =
  //   isLogin && user.vip_expiry > new Date().getTime() / 1000
  //     ? Math.ceil((user.vip_expiry - new Date().getTime() / 1000) / 3600 / 24)
  //     : 0;

  return (
    <>
      <header className=" sticky top-0 z-40 h-16 bg-primary">
        <Container className={className}>
          <div className="flex items-center justify-between h-full">
            <div className="flex items-center h-full flex-1">
              <Button
                type="text"
                className="flex items-center h-full pl-5 pr-3 md:hidden text-white text-2xl"
                onClick={() => setVisible(state => !state)}>
                {visible ? <CloseOutlined /> : <MenuOutlined />}
              </Button>
              <Link href="/">
                <a>
                  <img className="h-[44px] ml-4 object-contain" src="/static/offer.svg" />
                </a>
              </Link>
              <div className=" ml-3 lg:ml-10 xl:ml-[70px] flex-1 hidden md:flex text-white font-semibold text-base justify-around lg:justify-start lg:space-x-6 h-full">
                {navBarItems.map(({ title, href, subItems }) => (
                  <div
                    key={title}
                    className=" group relative px-2 lg:px-0 lg:w-[104px] text-center hover:bg-white group-hover:text-primary  cursor-pointer h-[calc(100%-8px)] mt-2 rounded-t-[20px] ">
                    <Link href={href || 'javascript:void(0)'}>
                      <a className="text-white group-hover:text-primary block h-full pt-3">{title}</a>
                    </Link>
                    {subItems && (
                      <div className="hidden bg-white shadow-card left-0 absolute top-[100%] pt-2 pb-5 group-hover:flex group-hover:flex-col gap-7 whitespace-nowrap z-50 rounded-b-md lg:w-[104px]">
                        {subItems.map(v => (
                          <Link href={v.href} key={v.href}>
                            <a className="font-bold text-sm text-#5E5E5E hover:text-primary">{v.title}</a>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                <div className=" hidden md:flex min-w-[40vw] md:min-w-0 max-w-[210px] items-center font-normal">
                  <NavSearch />
                </div>
              </div>
            </div>
            <div className="flex flex-shrink-0 items-center gap-x-3 mr-3 md:mr-0">
              {isLogin ? (
                <>
                  <Link href="/ps/draft">
                    <a className="align-middle text-white text-xl block">🧙</a>
                  </Link>
                  {isVip && (
                    <Link href="/account/plan">
                      <a className="align-middle text-white">
                        <img src="/static/crown.svg" className="inline w-[15px] h-[15px] mr-1 pb-0.5" />
                      </a>
                    </Link>
                  )}
                  <a href="https://instant.1point3acres.cn/notification" target="_blank">
                    <div className="relative">
                      <Badge size="small" count={user?.msg_count}>
                        <BellOutlined className="text-white text-lg" />
                      </Badge>
                    </div>
                  </a>
                  <Dropdown overlay={dropDownMenu} trigger={['hover', 'click']}>
                    <Avatar size={36} src={bbsEndpoint.AVATAR(user.uid)} className="cursor-pointer" />
                  </Dropdown>
                </>
              ) : (
                <>
                  <Link href="/ps/draft">
                    <a className="align-middle text-white text-xl block">🧙</a>
                  </Link>
                  <LoginButton>
                    <Button ghost>登录</Button>
                  </LoginButton>
                </>
              )}
            </div>
          </div>
        </Container>
      </header>
      <div
        className={` ${
          visible ? 'fixed' : 'hidden'
        } md:hidden left-0 top-16 w-screen h-[calc(100vh-64px)] z-[1000] bg-#FAFAFA overflow-auto`}>
        <div className="bg-white shadow-card p-5">
          <TypeaheadSelect placeholder="输入搜索内容" listHeight={350} className="w-full rounded-2xl" />
        </div>
        <div className="mx-2">
          <Collapse className="header-collapse" ghost expandIconPosition="end">
            {navBarItems.map(({ title, href, subItems }) => (
              <Collapse.Panel
                header={
                  <Link href={href || 'javascript:void(0)'}>
                    <a className="text-primary font-bold">{title}</a>
                  </Link>
                }
                key={title}>
                {subItems && (
                  <div className="flex flex-col">
                    {subItems.map(v => (
                      <Link href={v.href} key={v.href}>
                        <a className=" text-sm text-#5E5E5E h-full py-4">{v.title}</a>
                      </Link>
                    ))}
                  </div>
                )}
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </div>
      <style jsx global>{`
        .header-collapse {
          .ant-collapse-item > .ant-collapse-header {
            @apply border-b border-#DCDCDC;

            .ant-collapse-arrow {
              @apply text-primary text-lg;
            }
          }
        }
        .ant-badge-count {
          min-width: 14px;
          height: 14px;
          padding: 0 2px;
          font-size: 11px;
          line-height: 14px;
        }
      `}</style>
    </>
  );
}
