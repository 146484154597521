import Link from 'next/link';
import React from 'react';

import Layout from '~/components/Layout';
import { bbsEndpoint } from '~/utils/Constants';

const ErrorMessage = (link = '/') => {
  return {
    login: {
      message: '登录后才可以使用该功能',
      left: {
        name: '随便看看',
        link: '/',
      },
      right: {
        name: '前往登录',
        link: bbsEndpoint.LOGIN(link),
      },
    },
  };
};

const ErrorMessageView = ({ name, link }) => {
  const { message, left, right } = ErrorMessage(link)[name];
  return (
    <Layout>
      <div className="flex flex-col mt-20 md:mt-10">
        <div className="w-full text-center h-[300px]">
          <img src="/static/login.svg" className="w-auto h-full mx-auto" />
        </div>
        <div className="text-center text-lg mt-8">{message}</div>
        <div className="text-center text-base mt-4 space-x-2">
          <Link href={left.link}>
            <a>{left.name}</a>
          </Link>
          <span>|</span>
          <Link href={right.link}>
            <a rel="nofollow">{right.name}</a>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default ErrorMessageView;
