import _ from 'lodash';

export const SEOMap = {
  '/': {
    title: '留学申请',
    description:
      '查询留学录取结果，帮你定位选校, Offer多多是基于一亩三分地多年积累的申请背景和录取汇报数据，为你量身打造的一站式申请工具',
  },
  '/results': {
    title: '录取结果',
    description: '方便的查询世界各大知名院校，从2011到2021年留学录取结果，清晰查看申请人的背景',
  },
  '/colleges': {
    title: '世界知名院校数据库',
    description: '美国，英国，加拿大，澳大利亚，新西兰等世界知名学校数据库',
  },
  '/colleges/add': {
    title: '自助添加录取学校',
    description: '自助添加录取学校，完善一亩三分地学校数据库',
    loginRequired: true,
  },
  '/db/majors': {
    title: '专业库',
    description: '汇总留学申请各大热门专业，展示各个专业的平均GPA,GRE,托福,雅思等情况，对比各个专业申请人数',
  },
  '/db/faq': {
    title: '留学申请指南电子书',
    description: '通过问答的形式，介绍美国留学申请基本情况，回答英语考试，研究生申请，找工求职的各种问题。',
  },
  '/db/programs': {
    title: '热门留学申请项目',
    description: '美国，英国，加拿大，澳大利亚，新西兰各国CS, EE, MIS, ME, Chem, DataScience, Econ热门申请项目汇总',
  },
  '/db/cn-colleges': {
    title: '中国大陆高校录取分析报告',
    description: '大陆高校留学申请分析最新报告，以下学校根据一亩三分地录取汇报数据统计',
  },
  '/db/colleges': {
    title: '世界知名院校数据库',
    description:
      '美国，英国，加拿大，澳大利亚，新西兰等世界知名学校数据库，包含USNEWS最新排名，QS最新排名，评价，申请、录取，GPA等信息汇总',
  },
  '/db': {
    title: 'Offer多多学校项目专业数据库',
    description: 'Offer多多汇总的各大学校，项目，专业，数据结果数据库',
  },
  '/fiverr': {
    title: 'Fiverr文书修改',
    description: 'Fiverr 性价比最高的文书修改服务',
  },
  '/fiverr/post': {
    title: '参与Fiverr文书修改服务评价',
    description: '参与Fiverr文书修改服务评价， 帮助小伙伴，赢取大米奖励',
  },
  '/fiverr/editors': {
    title: 'Fiverr Editor列表',
    description: '基于一亩三分地多年积累的Fiverr Editor列表',
  },
  '/fiverr/reviews': {
    title: 'Fiverr评价列表',
    description: '基于一亩三分地多年积累的文书修改服务评价列表',
  },
  '/my': {
    title: '我的申请',
    description: '一亩三分地我的申请申请管理',
  },
  '/my/exams': {
    title: '英语考试',
    description: 'TOEFL, GRE, LETLS 等英语考试的相关准备讨论',
  },
  '/my/preparations': {
    title: '申请准备',
    description: '填写申请背景，准备申请文书',
  },
  '/my/favorites': {
    title: '我的关注',
    description: '关注我感兴趣的学校，项目，获得专属信息流',
  },
  '/my/recommend': {
    title: '智能选校',
    description: '根据你输入的申请背景，人工辅助生成选校名单',
  },
  '/my/targets': {
    title: '定位选校',
    description: '发布定位选校帖，查看定位选校的点评',
  },
  '/my/applications': {
    title: '申请进展',
    description: '汇报申请结果，总结申请流程，关注学校进展',
  },
  '/my/decision': {
    title: '比较决定',
    description: '比较申请结果，决定要去的学校',
  },
  '/my/travel': {
    title: '飞跃重洋',
    description: '寻找飞友，室友，一起探索新生活',
  },
  '/discuss/explore': {
    title: '各校进展',
    description: 'CMU, USC, NEU 各大学校申请/录取讨论',
  },
  '/pricing': {
    title: '白金卡套餐',
    description: 'Offer多多白金卡套餐',
  },
  '/stats/application': {
    title: '热门项目',
    description: '一亩三分地用户申请的热门项目',
  },
  '/stats/trend': {
    title: '数据趋势',
    description: '结果汇报/背景提交数据趋势图',
  },
  '/evaluation': {
    title: '定位选校',
    description: '发布定位选校帖',
    loginRequired: true,
  },
  '/report': {
    title: '结果汇报',
    description: '填写结果汇报帖',
    loginRequired: true,
  },
  '/compare': {
    title: '发求比较帖',
    description: '录取结果求比较',
    loginRequired: true,
  },
  '/ps': {
    title: '免费文书修改',
    description: '利用先进的人工智能免费帮您修改润色文书，轻松让您的文书更加出众',
  },
  '/ps/draft': {
    title: '留学申请文书草稿生成器',
    description: '利用先进的人工智能免费帮您生成文书草稿，轻松让您的文书更加出众',
  },
  '/ps/draft-history': {
    title: '文书草稿生成记录',
    description: '文书草稿生成记录',
  },
  '/background': {
    title: '我的背景',
    description: '填写我的申请背景',
    loginRequired: true,
  },
  '/userprogram': {
    title: '我的选校',
    description: '我的选校名单',
    loginRequired: true,
  },
  '/account/plan': {
    title: '我的套餐',
    description: '我的 offer 多多套餐',
    loginRequired: true,
  },
  '/guide': {
    title: '新手导读',
    description: 'Offer多多使用指南，轻松助您拿offer',
  },
  '/account/privacy': {
    title: '隐私设置',
    description: '我的隐私设置',
    loginRequired: true,
  },
};

export const loginRequiredPages = _.transform(
  SEOMap,
  (result, value, key) => {
    if (value?.loginRequired) {
      result.push(key);
    }
  },
  []
);
