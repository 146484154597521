import _ from 'lodash';
import fetch from 'isomorphic-unfetch';
import { message } from 'antd';

const defaultError = { response: { errno: -1, msg: '网站内部错误，可以通过右下角进行反馈' }, status: 500 };

message.config({
  top: 150,
  duration: 2,
  maxCount: 3,
});

class API {
  fetchData = (type, url, requestData) => {
    const defaultHeaders = {
      'Content-Type': 'application/json',
    };
    const options = {
      headers: defaultHeaders,
      method: type,
      credentials: 'include',
    };
    if (url.includes('/cookie/user')) {
      options.credentials = 'include';
      options.headers.cookie = requestData;
    }
    if (_.includes(['PUT', 'POST', 'DELETE'], type)) {
      options.body = JSON.stringify(requestData);
    }
    return fetch(url, options)
      .then(response => {
        if (response.status === 200) {
          return response;
        }
        throw response;
      })
      .then(response => response.json())
      .then(data => ({ response: data, status: 200 }))
      .catch(response => {
        if (response.status) {
          return response
            .json()
            .then(data => ({ response: data, status: response.status }))
            .catch(() => defaultError);
        }
        return defaultError;
      });
  };

  async fetch(object) {
    const method = _.get(object, 'method', 'GET');
    const params = _.get(object, 'params', {});
    const toastOnError = _.get(object, 'toastOnError', true);
    const urlPrefix = _.get(object, 'urlPrefix', '/api');
    const path = _.get(object, 'path', '');
    const res = await this.fetchData(method, `https://api.1point3acres.cn${urlPrefix}${path}`, params);
    const result = res.response;

    if (process.browser) {
      if (!_.includes([404], res.status) && result.errno < 0 && toastOnError) {
        message.error(result.msg);
      }
    }
    return result;
  }

  fetcher = url => fetch(url, { credentials: 'include' }).then(res => res.json());
}

export default new API();
