import React from 'react';
import { Spin, Button, Input } from 'antd';

import Avatar from '~/components/Avatar';
import { HTMLRender } from '~/components/html';
import Time from '~/components/common/Time';
import { Threads } from '~/services/agent';
import createModal from './createModal';

function ReportModal({ pid, onClose }) {
  const reportMutation = Threads.report();
  const [reportSuccess, setReportSuccess] = React.useState(false);
  const [content, setContent] = React.useState('');

  const handleSubmit = async () => {
    reportMutation.mutate(
      {
        pid,
        reason: content,
      },
      {
        onSuccess() {
          setReportSuccess(true);
          setContent('');
        },
      }
    );
  };

  return (
    <div>
      {reportSuccess ? (
        <div className="py-4 text-center">
          <div className="text-neutral-600 font-medium">
            感谢您的反馈！
            <br />
            我们已收到您的信息，当前评论已被收起。我们将会在
            <br />
            第一时间审核相关的内容
          </div>
          <Button type="primary" className="text-neutral-500 mt-12" onClick={onClose}>
            关闭窗口
          </Button>
        </div>
      ) : (
        <div className="px-6">
          <div className="text-neutral-600 font-medium">请输入您举报的原因</div>
          <Input.TextArea
            value={content}
            onChange={e => setContent(e.target.value)}
            className="bg-zinc-50 mt-4"
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
          <div className="mt-4 flex justify-end">
            <Button
              loading={reportMutation.isLoading}
              type="primary"
              className="text-neutral-500"
              onClick={handleSubmit}
              disabled={!content}>
              提交
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default createModal(ReportModal, { footer: null });
