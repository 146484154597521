import { useMedia } from 'react-use';

export default function useResponsive() {
  const sm = useMedia('(min-width: 640px)');
  const md = useMedia('(min-width: 768px)');
  const lg = useMedia('(min-width: 1024px)');
  const xl = useMedia('(min-width: 1280px)');
  const xxl = useMedia('(min-width: 1536px)');
  const mobile = useMedia('(max-width: 1024px)');
  return {
    sm,
    md,
    lg,
    xl,
    xxl,
    mobile,
  };
}
