import { LinkOutlined } from '@ant-design/icons';

function toShortURL(url) {
  return new URL(url).host.replace('www.', '');
}

function toProcessedUrl(url) {
  if (url.startsWith('https://link.1p3a.com/?url=')) {
    return url;
  }

  if (url.startsWith('https://link.1point3acres.com/?url=')) {
    return url.replace('https://link.1point3acres.com/?url=', 'https://link.1p3a.com/?url=');
  }

  if (url.match(/(https?|ftp|gopher|news|telnet|rtsp|mms|callto|bctp|thunder|qqdl|synacast){1}:\/\/|www\..+/)) {
    return `https://link.1p3a.com/?url=${encodeURIComponent(url)}`;
  }

  if (url.startsWith('forum.php')) {
    return `https://link.1p3a.com/?url=${encodeURIComponent(`https://www.1point3acres.com/bbs/${url}`)}`;
  }

  return url;
}

function toCnUrl(url) {
  let newUrl = url;
  if (!newUrl.startsWith('http')) {
    newUrl = 'https://www.1point3acres.com/bbs/' + newUrl;
  }
  if (newUrl.startsWith('https://www.1point3acres.com/bbs/thread')) {
    return newUrl.replace(/.+thread-(\d+)-.+/, (match, tid) => `https://offer.1point3acres.cn/thread/${tid}`);
  }
  return newUrl;
}

export default function BBLink({ href }) {
  const url = new URL(toCnUrl(href));
  const search = new URLSearchParams(url.search);
  const shortUrl = toShortURL(search.get('url') || url.href);

  return (
    <a
      href={toProcessedUrl(url.href)}
      target="_blank"
      rel="noreferrer"
      className="m-px inline-flex items-center break-all border-b px-1 text-primary underline border-light">
      <LinkOutlined className="mr-1" style={{ color: '#406C60' }} />
      {shortUrl}
    </a>
  );
}
