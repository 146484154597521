import { memo } from 'react';
import { Image } from 'antd';
import parse, { Element } from 'html-react-parser';

import { bbcode_to_html } from '~/components/bbcode';
import BBLink from '~/components/bbcode/BBLink';
import PostQuote from '~/components/bbcode/PostQuote';

const parser = input =>
  parse(input, {
    replace: domNode => {
      if (domNode instanceof Element && domNode.name === 'post-quote') {
        const { content, timestamp, username, pid } = domNode.attribs;
        if (!content || !timestamp || !username || !pid) return domNode;
        return <PostQuote content={content} timestamp={Number(timestamp)} username={username} pid={Number(pid)} />;
      }
      if (domNode instanceof Element && domNode.name === 'img') {
        return (
          <div>
            <Image src={domNode.attribs.src} className="max-h-[200px]" />
          </div>
        );
      }
      if (domNode instanceof Element && domNode.name === 'a') {
        return domNode.attribs.href ? <BBLink href={domNode.attribs.href} /> : domNode;
      }
    },
  });
export function Html({ text, pid = 0, isRawHtml = false, attachList = [] }) {
  const bbcodeHTML = isRawHtml ? text.replaceAll('</p><p>', '<br/>') : bbcode_to_html(text, { pid, attachList });

  const finalHTML = parser(bbcodeHTML);
  return (
    <div className="break-words text-base leading-7" style={{ wordBreak: 'break-word' }}>
      {finalHTML}
    </div>
  );
}

export const HTMLRender = memo(Html, (prev, next) => {
  return prev.text === next.text && prev?.pid === next?.pid && prev?.attachList === next?.attachList;
});
