export const navBarItems = [
  {
    title: '我的申请',
    href: '/my',
    subItems: [
      { title: '英语考试', href: '/my/exams' },
      { title: '申请准备', href: '/my/preparations' },
      { title: '我的关注', href: '/my/favorites' },
      { title: '智能选校', href: '/my/recommend' },
      { title: '定位选校', href: '/my/targets' },
      { title: '申请进展', href: '/my/applications' },
      { title: '比较决定', href: '/my/decision' },
      { title: '飞跃重洋', href: '/my/travel' },
    ],
    name: 'myapply',
  },
  {
    title: '数据库',
    href: '/db',
    subItems: [
      { title: '大学库', href: '/db/colleges' },
      { title: '项目库', href: '/db/programs' },
      { title: '专业库', href: '/db/majors' },
      { title: '文书修改🔥🔥', href: '/fiverr' },
      { title: '录取结果库', href: '/results' },
      { title: 'OFFER榜', href: '/admission' },
      { title: '出国报告', href: '/db/cn-colleges' },
      { title: '申请电子书', href: '/db/faq' },
    ],
    name: 'db',
  },
  {
    title: '发帖',
    subItems: [
      { title: '填背景', href: '/background' },
      { title: '求定位', href: '/evaluation' },
      { title: '报录取', href: '/report' },
      { title: '求比较', href: '/compare' },
    ],
    name: 'post',
  },
  {
    title: 'Offer白金卡',
    href: '/pricing',
    subItems: [
      { title: '全部套餐', href: '/pricing' },
      { title: '我的套餐', href: '/account/plan' },
      { title: '文书草稿生成', href: '/ps/draft' },
    ],
    name: 'vip',
  },
  {
    title: '新手导读',
    href: '/guide',
    name: 'guide',
  },
];

export const dbMenus = [
  ['大学库', '/db/colleges'],
  ['项目库', '/db/programs'],
  ['专业库', '/db/majors'],
  ['录取结果库', '/results'],
  ['OFFER榜', '/admission'],
  ['出国报告', '/db/cn-colleges'],
  ['申请指南电子书', '/db/faq'],
];

export function getDBMenuItem(path) {
  return dbMenus.find(v => path.startsWith(v[1]));
}
