import { useQuery } from 'react-query';

import axios, { request as getRequest } from '~/services/axios';
import useLogin from '~/hooks/useLogin';
import { queryClient } from '~/utils/QueryClient';

export default function(url, options, plainRequest = false) {
  if (plainRequest || (typeof window === 'undefined' && !queryClient.getQueryData([url]))) {
    return getRequest(url);
  }

  const { requireLogin } = useLogin();
  return useQuery([options?.key || url], () => axios(url).then(res => res.data), {
    enabled: Boolean(url) && requireLogin,
    ...options,
  });
}
