import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Modal, ModalProps } from 'antd';

export default function createModal(Component, modalProps) {
  return NiceModal.create(props => {
    const modal = useModal();
    const { hide } = modal;

    function handleClose() {
      hide();
    }

    return (
      <Modal {...antdModal(modal)} {...modalProps}>
        <Component onClose={handleClose} {...props} />
      </Modal>
    );
  });
}
