/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import url from 'url';
import bbobHTML from '@bbob/html';
import presetHTML5 from '@bbob/preset-html5';
import * as cheerio from 'cheerio';
import _ from 'lodash';
import queryString from 'query-string';
import dayjs from 'dayjs';

import { getPreviewableUrlType } from './bbcode-helper';

const ALLOWED_BBCODE = [
  'font',
  'size',
  'url',
  'code',
  'backcolor',
  'email',
  'i',
  'b',
  'color',
  'quote',
  'attach',
  'code',
  'img',
  'hide',
  'list',
  'li',
  'u',
  '*',
  'table',
  'tr',
  'td',
  'p',
  'indent',
  'media',
  'hr',
  'ad',
];

const preset = presetHTML5.extend(tags => ({
  ...tags,
  hide: (node, context) => {
    const contentStr = context.render(node.content);
    if (contentStr.includes('查看如何攒积分')) {
      const credits = Object.keys(node.attrs)[0];
      return {
        tag: 'hide',
        attrs: {
          credits: credits || 188,
        },
        content: '积分不足提示',
      };
    }
    return {
      tag: 'span',
      content: node.content,
    };
  },
  indent: node => {
    return {
      tag: 'p',
      content: node.content,
    };
  },
  attach: (node, context) => {
    const attachId = node.content[0];
    const attachment = context.data.attachList?.find(a => String(a.aid) === String(attachId));
    if (attachId && attachment?.isimage) {
      return {
        tag: 'img',
        attrs: {
          src: attachment.url,
          width: attachment?.width,
          height: attachment?.height,
        },
      };
    } else {
      return {
        tag: 'blockquote',
        content: {
          tag: 'font',
          attrs: {
            size: `1`,
          },
          content: `文中含有App暂不支持的附件，请到论坛下载`,
        },
      };
    }
  },
  img: (node, context) => {
    const contentStr = context.render(node.content);
    if (!contentStr) {
      return null;
    }
    // if (contentStr.startsWith("data:image/svg")) {
    //   return null;
    // }
    return {
      tag: 'img',
      attrs: {
        src: contentStr,
      },
    };
  },
  media: node => {
    const mediaURl = node.content[0];
    return {
      tag: 'a',
      attrs: {
        href: mediaURl,
      },
      content: node.content,
    };
  },
  quote: (node, context) => {
    // 检查是否是回复引用
    try {
      const quoteContent = node.content
        .slice(1)
        .join('')
        .replaceAll('<br/>', '')
        .trim();
      const sizeNode = node.content[0];
      const urlNode = sizeNode.content[0];
      const colorNode = urlNode.content[0];
      const colorNodeContent = colorNode.content;
      //console.log(quoteContent);
      if (
        sizeNode.tag === 'size' &&
        sizeNode.attrs['2'] === '2' &&
        urlNode.tag === 'url' &&
        colorNode.tag === 'color'
      ) {
        const userInfo = colorNodeContent.join('');
        const parts = userInfo.split('发表于');
        let username = '';
        let timestamp = 0;
        if (parts.length === 2) {
          username = parts[0].trim();
          timestamp = dayjs(parts[1].trim()).unix();
        }
        // const url = bbsEndpoint.HOME + Object.keys(urlNode.attrs)[0];
        // const quotePid = extractPid(url);
        return {
          tag: 'post-quote',
          attrs: {
            username: username,
            timestamp: timestamp,
            pid: context.data.pid || 0,
            content: quoteContent,
          },
        };
      }
    } catch (error) {}
    return {
      tag: 'blockquote',
      content: node.content,
    };
  },
  email: node => {
    return {
      tag: 'a',
      attrs: {
        href: `mailto:${node.content[0]}`,
      },
      content: node.content,
    };
  },
  size: node => {
    // 统一成font 在 render里处理
    const fontSize = parseInt(Object.keys(node.attrs)[0], 10);
    return {
      tag: 'font',
      attrs: {
        size: `${fontSize}`,
      },
      content: node.content,
    };
  },
  backcolor: node => {
    return {
      tag: 'span',
      content: node.content,
    };
  },
  color: node => {
    const defaultNode = {
      tag: 'span',
      attrs: {},
      content: node.content,
    };
    try {
      const color = Object.keys(node.attrs)[0].toLowerCase();
      // 去掉不适合夜间模式的颜色
      if (['#000', 'black', 'white', '#fff', '#ff0ff'].includes(color)) {
        return defaultNode;
      }
      return {
        tag: 'span',
        attrs: {
          style: `color:${color}`,
        },
        content: node.content,
      };
    } catch (error) {}

    return defaultNode;
  },
  i: node => {
    if (!_.isEmpty(node.content)) {
      return {
        tag: 'span',
        attrs: {
          style: `font-style: italic;`,
        },
        content: node.content,
      };
    }
    return '[i]';
  },
  code: node => {
    const content = node.content.join('');
    return {
      tag: 'pre',
      attrs: {
        style: `border: 1px solid #ccc; padding: 10px;`,
      },
      content: content,
    };
  },
  ad: () => {
    return {
      tag: 'ad',
    };
  },
}));

export function bbcode_to_html(text, context, options = { widget: false }) {
  // bug https://github.com/JiLiZART/BBob/issues/125
  let result = bbobHTML(
    text
      .replaceAll('[/quote]\n\n', '[/quote]\n')
      .replaceAll('[/attach]\r\n', '[/attach]')
      .replace(/\r\n\[\*\]/g, '[*]')
      .replace(/(\r?\n)/g, '<br/>'),
    preset(),
    {
      data: context,
      contextFreeTags: ['code'],
      onlyAllowTags: ALLOWED_BBCODE,
    }
  );
  // 合并多个换行
  result = result.replace(/(<br\/>\s*){3,}/g, '<br/><br/>');
  const $ = cheerio.load(result);

  if (options.widget) {
    $('a').each((index, element) => {
      const href = $(element).attr('href');
      const content = $(element).text();
      const previewableUrlType = getPreviewableUrlType(href || '');
      const isTextableBBSLink = previewableUrlType === 'bbs' && content !== href;
      if (href && previewableUrlType && !isTextableBBSLink) {
        let iframeHref = href;
        if (href.startsWith('https://link.1p3a.com')) {
          const { search } = url.parse(href);
          if (search) {
            const urlValue = queryString.parse(search).url;
            if (typeof urlValue === 'string') {
              iframeHref = urlValue;
            }
          }
        }
        const widget = `<media>https://preview.1p3a.com/?url=${encodeURIComponent(iframeHref)}</media>`;
        let targetElement = element;
        while (targetElement.next) {
          targetElement = targetElement.next;
          if (targetElement.type === 'tag' && targetElement.name === 'br') {
            break;
          }
        }
        if (targetElement.next) {
          $(widget).insertBefore(targetElement);
        } else {
          $(widget).insertAfter(targetElement);
        }
      }
    });
  }

  return $('body').html() || '';
}
