import React from 'react';

import LoginContext from '~/contexts/LoginContext';

export default function useLogin() {
  const isLogin = React.useContext(LoginContext);
  return {
    isLogin,
    requireLogin: isLogin == null ? true : isLogin,
  };
}
