import React from 'react';
import { SearchOutlined } from '@ant-design/icons';

import TypeaheadSelect from '~/components/search/TypeaheadSelect';

const NavSearch = () => {
  const [expanded, setExpanded] = React.useState(false);

  if (!expanded) {
    return <SearchOutlined className="text-white text-lg text-right" onClick={() => setExpanded(true)} />;
  }

  return <TypeaheadSelect placeholder="搜索学校/项目/申请结果" listHeight={350} className="w-full md:w-52" />;
};

export default NavSearch;
