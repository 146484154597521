import { decode } from 'html-entities';
import _ from 'lodash';

const patternBBCodeToRemoveAttach = /\[attach\]\d+\[\/attach\]/gi;

const patternBBCodeToRemoveQuote = /\[quote\]((?!\[\/quote\])(.|\r\n|\n))*\[\/quote\]/gi;

const patternBBCodeToRemoveGeneral = /\[\/?(i|b|url=([^\]]*)|url|u|list|li|color=([^\]]*)|color|size(=[^\]]*)|size|ad)\]/gi;

const patternBBCodeToRemoveList = /\[list.*?\].*?\[\/list\]/gi;

export function removePostBBCode(content) {
  let parsedContent = content;
  if (typeof parsedContent !== 'string' || _.isEmpty(parsedContent)) {
    return parsedContent;
  }
  parsedContent = parsedContent.replace(patternBBCodeToRemoveList, '');

  parsedContent = parsedContent.replace(patternBBCodeToRemoveQuote, '');

  parsedContent = parsedContent.replace(patternBBCodeToRemoveGeneral, '');

  parsedContent = parsedContent.replace(patternBBCodeToRemoveAttach, '');
  parsedContent = parsedContent.replace(/(\r?\n)/g, '');
  return decode(parsedContent);
}

export function removeHTMLTags(content) {
  return content.replace(/<[^>]*>/g, '');
}

export function getPreviewableUrlType(url) {
  try {
    const urlType =
      _.findKey(
        {
          video:
            '.*((youtube\\.com.*\\/watch\\?v=[^"&?/\\s]{11})|(youtube\\.com.*\\/shorts\\/[^"&?/\\s]{11})|(youtu\\.be\\/[^"&?/\\s]{11})' +
            '|(open\\.spotify\\.com\\/(playlist|track|album|episode)\\/[^"&?/\\s]+)' +
            '|(bilibili\\.com\\/video\\/[^"&?/\\s]{12})|(twitch\\.tv\\/videos\\/\\d+)' +
            '|(podcasts\\.apple\\.com\\/[^/]+\\/podcast\\/)).*',
          other: '.*((amazon\\.com.*\\/([A-Z0-9]{10}))|(twitter\\.com\\/.*\\/status\\/\\d+)|teamblind\\.com).*',
          bbs: '.*((1point3acres\\.com\\/bbs\\/(thread|forum|tag)-\\d+)).*',
        },
        pattern => decodeURIComponent(url).match(new RegExp(pattern))
      ) || false;
    if (urlType === 'video') {
      return false;
    }
    return urlType;
  } catch (err) {
    return false;
  }
}

const patternTrailingAttachments = /\[attach\]\d+\[\/attach\][\s]*$/i;

export const removeTrailingAttachments = content => {
  // remove trailing attach tags, since all image attachments are already displayed in the carousel
  let processedContent = content;
  while (patternTrailingAttachments.test(processedContent)) {
    processedContent = processedContent.replace(patternTrailingAttachments, '');
  }
  return processedContent;
};

export const removeContentInBrackets = content => {
  if (typeof content !== 'string' || _.isEmpty(content)) {
    return content;
  }
  return content.replace(/【.*?】/, '');
};
