import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 3000,
    },
  },
});

const queryCache = queryClient.getQueryCache();
queryCache.subscribe(() => {
  const cache = queryCache.getAll().filter(v => v.queryHash.includes('ps') && v.state.isFetching);
  if (cache.length) {
    window.scrollTo(0, 0);
  }
});
