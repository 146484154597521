import { useQuery } from 'react-query';

import axios, { request as getRequest } from '~/services/axios';

export default function useCustomQuery(url, options) {
  const key = options?.key || url;

  function prefetch(queryClient) {
    return queryClient
      .prefetchQuery([url], () => {
        return getRequest(url).then(({ res }) => res);
      })
      .then(() => queryClient.getQueryData([url]));
  }

  function hook() {
    const { data, ...rest } = useQuery([key], () => axios(url).then(res => res.data), {
      enabled: Boolean(url),
      ...options,
    });
    return {
      data: options?.format ? options.format(data) : data,
      ...rest,
    };
  }
  return {
    key,
    hook,
    prefetch,
  };
}
