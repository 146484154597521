import React from 'react';
import _ from 'lodash';
import { Skeleton } from 'antd';
import { User } from '~/services/agent';
import { loginRequiredPages } from '~/utils/seo_map';
import Layout from '~/components/Layout';
import ErrorMessageView from './ErrorMessageView';

const ProtectRoute = ({ children }) => {
  const { isLogin, isLoading } = User.getMyProfile();

  if (process.browser) {
    const isLoginRequired = _.find(loginRequiredPages, prefix => window.location.pathname.startsWith(prefix));
    if (isLoginRequired && isLoading) {
      return (
        <Layout>
          <Skeleton paragraph={{ rows: 8 }} />
        </Layout>
      );
    }
    if (isLoginRequired) {
      if (!isLogin) {
        return <ErrorMessageView name="login" link={window.location.href} />;
      }
    }
  }
  return children;
};

export default ProtectRoute;
