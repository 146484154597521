import NiceModal from '@ebay/nice-modal-react';
import Time from '~/components/common/Time';

export default function PostQuote(props) {
  const { content, timestamp, username, pid } = props;
  function handleClick() {
    NiceModal.show('post', { pid });
  }
  return (
    <div
      className="cursor-pointer rounded-r-md border-l-4 p-3 bg-#FAFAFA border-#ABABAB space-y-2.5"
      onClick={handleClick}>
      <div className="text-xs underline">
        {username}&nbsp; 发表于
        <Time timestamp={timestamp} fromNow />
      </div>
      <div className="text-sm">{content}</div>
      <div className="text-right text-sm text-primary">查看相关评论</div>
    </div>
  );
}
