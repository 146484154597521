import React from 'react';
import { Select } from 'antd';
import { useDebounce } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useRouter } from 'next/router';
import _ from 'lodash';

import agent from '~/stores/Agent';
import useResponsive from '~/hooks/useResponsive';

const Loading = () => (
  <>
    <div className="loading">
      <LoadingOutlined style={{ fontSize: 12 }} spin />
      <span>正在查询...</span>
    </div>
    <style jsx>{`
      .loading {
        padding-left: 12px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--white-two);

        span {
          margin-left: 8px;
        }
      }
    `}</style>
  </>
);

const TypeaheadSelect = props => {
  const [searchResults, setSearchResults] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState();
  const { mobile } = useResponsive();
  const router = useRouter();

  const search = async v => {
    if (v != null) {
      const res = await agent.Database.search(v);
      setSearchResults(res?.data);
      setLoading(false);
    }
  };

  useDebounce(() => search(value), 500, [value]);

  const handleSearch = v => {
    setLoading(true);
    setValue(v);
  };

  const handleFocus = () => {
    search('');
  };

  const handleSelect = v => {
    setLoading(true);
    router.push(v);
    setLoading(false);
  };

  const dropdownRender = menu => {
    return (
      <>
        {loading && <Loading />}
        {menu}
      </>
    );
  };

  return (
    <div className="w-full lg:w-auto">
      <Select
        className="w-full"
        showSearch
        allowClear
        filterOption={false}
        dropdownRender={dropdownRender}
        onSearch={handleSearch}
        onFocus={handleFocus}
        onSelect={handleSelect}
        dropdownMatchSelectWidth={mobile}
        optionLabelProp="label"
        {...props}>
        <Select.Option key="related_colleges" value="/db/colleges" label="更多学校">
          <div className="w-full flex justify-between px-1 my-2 border-b border-b-#5E5E5E cursor-default">
            <span>{_.isEmpty(searchResults?.colleges) ? '未找到' : ''}相关学校</span>
            <span className="text-primary cursor-pointer">更多学校</span>
          </div>
        </Select.Option>
        {(searchResults?.colleges || []).map(option => {
          const dashSchoolName = option.school_name ? `-${option.school_name}` : '';
          return (
            <Select.Option
              key={option.short_name}
              value={`/university/${option.short_name.toLowerCase()}`}
              label={`${option.short_name} ${option.school_name}`}>
              <div className="px-1">
                <Highlighter
                  searchWords={[value]}
                  textToHighlight={mobile ? option.short_name : `${option.short_name}${dashSchoolName}`}
                  highlightStyle={{ fontWeight: 'bold', background: 'none', padding: '0px' }}
                  unhighlightStyle={{ color: 'var(--black)' }}
                />
                <div className="mute">{option.school_name_cn}</div>
                <div className="flag">{option.area}</div>
              </div>
            </Select.Option>
          );
        })}
        <Select.Option key="related_programs" value="/db/programs" label="更多项目">
          <div className="w-full flex justify-between px-1 my-2 border-b border-b-#5E5E5E cursor-default">
            <span>{_.isEmpty(searchResults?.programs) ? '未找到' : ''}相关项目</span>
            <span className="text-primary cursor-pointer">更多项目</span>
          </div>
        </Select.Option>
        {(searchResults?.programs || []).map(option => {
          return (
            <Select.Option
              key={`${option.program_id}`}
              value={`/program/${option.seo_url}`}
              label={`${option.short_name} ${option.program_name}`}>
              <div className="px-1">
                <Highlighter
                  searchWords={[value]}
                  textToHighlight={option.program_name}
                  highlightStyle={{ fontWeight: 'bold', background: 'none', padding: '0px' }}
                  unhighlightStyle={{ color: 'var(--black)' }}
                />
                <div className="mute">{option.short_name}</div>
              </div>
            </Select.Option>
          );
        })}
        <Select.Option key="related_results" value="/results" label="更多结果">
          <div className="w-full flex justify-between px-1 my-2 border-b border-b-#5E5E5E cursor-default">
            <span>{_.isEmpty(searchResults?.results) ? '未找到' : ''}相关申请结果</span>
            <span className="text-primary cursor-pointer">更多结果</span>
          </div>
        </Select.Option>
        {(searchResults?.results || []).map(option => {
          return (
            <Select.Option
              key={option.result_id}
              value={`/result/${option.result_id}`}
              label={`${option.school_name} ${option.planmajor}  ${option.plandegree}`}>
              <div className="px-1">
                <Highlighter
                  searchWords={[value]}
                  textToHighlight={`${option.school_name} ${option.planmajor} ${option.plandegree}`}
                  highlightStyle={{ fontWeight: 'bold', background: 'none', padding: '0px' }}
                  unhighlightStyle={{ color: 'var(--black)' }}
                />
                <div className="mute">{option.result}</div>
              </div>
            </Select.Option>
          );
        })}
      </Select>
      <style jsx>{`
        .mute {
          font-size: 12px;
          color: var(--greyish-brown);
        }
        .option {
          display: flex;
          justify-content: space-between;
        }
        .flag {
          align-self: center;
          color: initial;
          margin-right: 5px;
        }
      `}</style>
    </div>
  );
};

export default TypeaheadSelect;
