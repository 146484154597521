import axios from 'axios';
import { message } from 'antd';
import { isServer } from '~/utils/Constants';

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: 'https://api.1point3acres.cn',
  headers: {
    'User-Agent': `axios/offer${isServer ? '/vercel' : ''}`,
  },
});

instance.interceptors.request.use(
  config => {
    config.url = encodeURI(config.url);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status !== 403 && typeof document !== 'undefined' && error?.response?.data?.errno < 0) {
      const msg = error?.response?.data?.msg ?? error.message;
      message.error(msg);
      return {
        data: {
          errno: -1,
          msg,
        },
      };
    }
    throw error;
  }
);

export async function request(config) {
  return await instance(config)
    .then(data => {
      return {
        res: data?.data,
      };
    })
    .catch(err => {
      return {
        error: err,
        res: {
          errno: -1,
        },
      };
    });
}

export default instance;
