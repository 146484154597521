import api from '../utils/API';

const Auth = {
  login: params => api.fetch({ method: 'POST', path: '/signin', params, toastOnError: false }),
  getUserFromToken: () => api.fetch({ method: 'GET', path: '/token/user', toastOnError: false }),
  getUserFromDiscuz: params => api.fetch({ method: 'GET', path: '/cookie/user', params, toastOnError: false }),
  getUserFromWechat: code => api.fetch({ method: 'GET', path: `/wechat/user?code=${code}`, toastOnError: false }),
};

const User = {
  getProfile: uid => api.fetch({ method: 'GET', path: `/users/${uid}/profile` }),
};

const Accounts = {
  getMyAccounts: () => api.fetch({ method: 'GET', path: '/accounts/my', urlPrefix: '/offer' }),
  setPrivacyBackground: background_expiry =>
    api.fetch({
      method: 'POST',
      path: '/accounts/privacy/background',
      urlPrefix: '/offer',
      params: { background_expiry },
    }),
  setPrivacyResult: actions =>
    api.fetch({
      method: 'POST',
      path: '/accounts/privacy/result',
      urlPrefix: '/offer',
      params: {
        actions,
      },
    }),
  saveContact: contact =>
    api.fetch({ method: 'POST', path: '/accounts/privacy/contact', params: { contact }, urlPrefix: '/offer' }),
};

const Programs = {
  getProgramList: (ps, pg, sort_field, sort_order, filters) =>
    api.fetch({
      method: 'POST',
      path: `/programs-search?ps=${ps}&pg=${pg}`,
      params: { sort_field, sort_order, filters },
      urlPrefix: '/offer',
    }),
  queryPrograms: (schoolShort, degree) =>
    api.fetch({ method: 'GET', path: `/programs?school=${schoolShort}&degree=${degree}`, urlPrefix: '/offer' }),
  getProgramReason: id => api.fetch({ method: 'GET', path: `/programs/${id}/reasons`, urlPrefix: '/offer' }),
  addNewProgram: params => api.fetch({ method: 'POST', path: `/programs`, params, urlPrefix: '/offer' }),
  updateProgram: (pid, params) => api.fetch({ method: 'PUT', path: `/programs/${pid}`, params, urlPrefix: '/offer' }),
  getProgramStats: pid => api.fetch({ method: 'GET', path: `/stats/programs/${pid}`, urlPrefix: '/offer' }),
};

const Colleges = {
  addNewCollege: params => api.fetch({ method: 'POST', path: `/colleges`, params, urlPrefix: '/offer' }),
  getSchoolDetail: shortSchool => api.fetch({ method: 'GET', path: `/colleges/${shortSchool}`, urlPrefix: '/offer' }),
  getSchoolStats: shortSchool =>
    api.fetch({ method: 'GET', path: `/stats/colleges/${shortSchool}`, urlPrefix: '/offer' }),
  getMajorStats: (shortSchool, majorId) =>
    api.fetch({ method: 'GET', path: `/stats/colleges/${shortSchool}/majors/${majorId}`, urlPrefix: '/offer' }),
  getCollegeInfo: shortSchool => api.fetch({ method: 'GET', path: `/colleges/${shortSchool}`, urlPrefix: '/offer' }),
  getUsers: (shortSchool, pg) =>
    api.fetch({ method: 'GET', path: `/colleges/${shortSchool}/users?ps=15&pg=${pg}`, urlPrefix: '/offer' }),
  search: (query, includeCN = 0) =>
    api.fetch({
      method: 'GET',
      path: `/colleges/typeahead?query=${query}&include_cn=${includeCN}`,
      urlPrefix: '/offer',
    }),
  getCNSchoolStats: (schoolName, planyr) =>
    api.fetch({ method: 'GET', path: `/cn-colleges/${schoolName}?planyr=${planyr}`, urlPrefix: '/offer' }),
  getCNSchoolList: () => api.fetch({ method: 'GET', path: `/cn-colleges`, urlPrefix: '/offer' }),
};

const Offer = {
  getOfferResultList: (ps, pg, filters) =>
    api.fetch({ method: 'POST', path: `/results?ps=${ps}&pg=${pg}`, params: { filters }, urlPrefix: '/offer' }),
  getUserOfferResult: uid => api.fetch({ method: 'GET', path: `/users/${uid}/results`, urlPrefix: '/offer' }),
  getMatchedUsers: () => api.fetch({ method: 'GET', path: `/matches/users`, urlPrefix: '/offer' }),
  getMatchedSeniors: () => api.fetch({ method: 'GET', path: `/matches/seniors`, urlPrefix: '/offer' }),
  getResultStatsTrend: filters =>
    api.fetch({ method: 'POST', path: `/stats/trends/results`, params: { filters }, urlPrefix: '/offer' }),
  createResultThread: params => api.fetch({ method: 'POST', path: `/result-threads`, params, urlPrefix: '/offer' }),
  getResultThreads: () => api.fetch({ method: 'GET', path: `/result-threads`, urlPrefix: '/offer' }),
  getResultDetail: id => api.fetch({ method: 'GET', path: `/results/${id}`, urlPrefix: '/offer' }),
  compare: params => api.fetch({ method: 'POST', path: '/compare-threads', params, urlPrefix: '/offer' }),
};

const Background = {
  getEvaluationThreads: () => api.fetch({ method: 'GET', path: '/background-threads', urlPrefix: '/offer' }),
};

const Article = {
  getApplicationSummary: (ps, pg, filters) =>
    api.fetch({
      method: 'POST',
      urlPrefix: '/offer',
      path: `/articles/summary?ps=${ps}&pg=${pg}`,
      params: { filters },
    }),
};

// 论坛数据获取
const Discuss = {
  getThreads: ({ params, includes = 'summary', order = 'time_desc', pg = 1, ps = 10 }) =>
    api.fetch({
      method: 'POST',
      urlPrefix: '/api',
      path: `/forums/361/types/314/threads?includes=${includes}&order=${order}&ps=${ps}&pg=${pg}`,
      params,
    }),
  getThreadDetail: tid =>
    api.fetch({
      method: 'GET',
      urlPrefix: '/api',
      path: `/v2/threads/${tid}`,
    }),
  getThreadPosts: (tid, pg = 1) =>
    api.fetch({
      method: 'GET',
      urlPrefix: '/api',
      path: `/v2/threads/${tid}/posts?ps=15&pg=${pg}`,
    }),
  getProgramInfo: programId =>
    api.fetch({
      method: 'GET',
      urlPrefix: '/offer',
      path: `/programs/${programId}`,
    }),
  getUsers: (programId, pg) =>
    api.fetch({
      method: 'GET',
      urlPrefix: '/offer',
      path: `/programs/${programId}/users?ps=15&pg=${pg}`,
    }),
  sendMessage: (tid, params) =>
    api.fetch({
      method: 'POST',
      urlPrefix: '/api',
      path: `/threads/${tid}/posts`,
      params,
    }),
  getThreadVotes: tid =>
    api.fetch({
      method: 'GET',
      urlPrefix: '/api',
      path: `/threads/${tid}/votes`,
    }),
  voteThread: (tid, type) =>
    api.fetch({
      method: 'PUT',
      urlPrefix: '/api',
      path: `/threads/${tid}/votes`,
      params: { type },
    }),
  addRate: params =>
    api.fetch({
      method: 'POST',
      urlPrefix: '/api',
      path: '/rate',
      params,
    }),
  getUserRateStatus: pid =>
    api.fetch({
      method: 'GET',
      urlPrefix: '/api',
      path: `/rate/${pid}`,
    }),
  getPostRates: pid =>
    api.fetch({
      method: 'GET',
      urlPrefix: '/api',
      path: `/rate/${pid}/ratelogs`,
    }),
  postThread: params =>
    api.fetch({
      method: 'POST',
      urlPrefix: '/offer',
      path: '/discuss-threads',
      params,
    }),
  getChats: search =>
    api.fetch({
      method: 'GET',
      urlPrefix: '/offer',
      path: `/discuss-chats?${search}`,
    }),
  postChats: params =>
    api.fetch({
      method: 'POST',
      urlPrefix: '/offer',
      path: `/discuss-chats`,
      params,
    }),
};

const Database = {
  search: query =>
    api.fetch({
      method: 'GET',
      path: `/search?q=${query}`,
      urlPrefix: '/offer',
    }),
};

export default {
  Auth,
  User,
  Offer,
  Background,
  Article,
  Programs,
  Colleges,
  Discuss,
  Accounts,
  Database,
};
